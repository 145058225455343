import {environment} from "../_enviroments/enviroments";

export const userService = {
    login,
    register,
    checkCode,
    logout
};

const url = environment.production;

function login(username, password) {

    const formData = new FormData();

    formData.append('username', username);
    formData.append('password', password);
    formData.append('device', 'testd');
    formData.append('firebase', 'web-referal');

    const requestOptions = {method: 'POST', mode: 'cors', body: formData};

    return fetch(url + `api/auth/sign-in`, requestOptions)
        .then(handleResponse)
        .then(token => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('token', JSON.stringify(token));
            return token;
        })
}

function register(username, country, city) {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('phone', username);
    formData.append('city', city);
    formData.append('country', country);
    formData.append('device', 'testd');
    formData.append('firebase', 'web-referal');
    const requestOptions = {method: 'POST', mode: 'cors', body: formData};

    return fetch(url + `api/auth/registration`, requestOptions)
        .then(handleResponse)
}

function checkCode(code, phone) {
    const formData = new FormData();

    formData.append('phone', phone);
    formData.append('code', code);

    const requestOptions = {method: 'POST', mode: 'cors', body: formData};

    return fetch(url + `api/auth/verify-sms-code`, requestOptions)
        .then(handleResponse)
        .then(token => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('token', JSON.stringify(token));
            return token;
        })
}

function logout() {
    const token = JSON.parse(localStorage.getItem("token"));
    const formData = new FormData();

    formData.append('token', token.token);
    formData.append('device', 'testd');

    const requestOptions = {method: 'POST', mode: 'cors', body: formData};

    return fetch(url + `api/auth/sign-out`, requestOptions)
        .then(handleResponse)
        .then(token => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.removeItem('token');
            return token
        })
}

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                window.location = '/';
            }
            // if (response.status === 422) {
                // auto logout if 401 response returned from api
               // location.reload(true);
            // }
            const error = (data.type && data.error) || data.error;
            return Promise.reject(error);
        }

        return data;
    });
}
