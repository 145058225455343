import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import LogoLoader from "../../../assets/logo/LogoLoader";

export const usePreloaderStyles = makeStyles((theme) => ({
        preloader: {
            position: 'fixed',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            background: "#fff",
            zIndex: 1001,
        },
        preloaderRow: {
            position: 'relative',
            top: '50%',
            left: '50%',
            width: 70,
            height: 70,
            marginTop: -35,
            marginLeft: -35,
            textAlign: 'center',
            animation: 'preloader-rotate 2s infinite linear'
        },
        preloaderItem: {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            top: 0,
            backgroundColor: '#0076FF',
            borderRadius: '100%',
            width: 35,
            height: 35,
            animation: 'preloader-bounce 2s infinite ease-in-out',
            '&.first svg': {
                transform: 'rotate(180deg)'
            },
            '&:last-child': {
                top: 'auto',
                bottom: 0,
                animationDelay: '-1s'
            }
        },
        '@keyframes preloader-rotate': {
            '100%': {
                transform: 'rotate(360deg)'
            }
        },
        '@keyframes preloader-bounce': {
            '0%, 100%': {
                transform: 'scale(0)'
            },
            '50%': {
                transform: 'scale(1)'
            },
        }
    })
);

function Loader() {

    const classes = usePreloaderStyles();

    return (
        <div className={classes.preloader}>
            <div className={classes.preloaderRow}>
                <div className={classes.preloaderItem + ' first'}>
                    <LogoLoader width={18}/>
                </div>

                <div className={classes.preloaderItem}>
                    <LogoLoader width={18}/>
                </div>
            </div>
        </div>
    );
}

export {Loader};
