import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

import reportWebVitals from './reportWebVitals';

import './index.css';

import {App} from './main/pages/app/App';
import {store} from './main/store/_helpers';

const preloader = document.querySelector('.preloader');
const showLoader = () => preloader.classList.remove('preloader--hide');
const hideLoader = () => preloader.classList.add('preloader--hide');

setTimeout(() =>
    ReactDOM.render(
        <Provider store={store}>
            <App
                hideLoader={hideLoader}
                showLoader={showLoader}
            />
        </Provider>,
        document.getElementById('root')
    ), 1000
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
