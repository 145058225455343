import {userConstants} from "../_constants";

export function mentorInfo(state = {}, action) {
    switch (action.type) {
        case userConstants.USER_GET_INFO_MENTOR_REQUEST:
            return {loader: true, getMentor: false};
        case userConstants.USER_GET_INFO_MENTOR_SUCCESS:
            return { mentor: action.mentorInfo,  type: action.type, loader: false, getMentor: true};
        case userConstants.USER_GET_INFO_MENTOR_FAILURE:
            return {error: action, type: action.type,  loader: false, getMentor: true};
        default:
            return state
    }
}
