import {Button, withStyles} from "@material-ui/core";

export const BlueButton = withStyles((theme) => ({
    root: {
        zIndex: 0,
        padding: '8px 24px',
        textTransform: 'capitalize',
        color: '#fff',
        fontWeight: '600',
        backgroundColor: "#0063F7",
        boxShadow: 'none',
        lineHeight: '1.4',
        borderRadius: 8,
        border: '1px solid #007AFF',

        '&.Mui-disabled': {
            border: '1px solid transparent',
            backgroundColor: '#E4E4EB',
            color: '#8F90A6'
        },
        '&:hover': {
            backgroundColor: '#004FC4',
            boxShadow: 'none',
        },
        '& .MuiButton-contained.Mui-focusVisible': {
            backgroundColor: '#004FC4',
            boxShadow: 'none',
        },
    },
}))(Button);

export const BlueBorderButton = withStyles((theme) => ({
    root: {
        padding: 8,
        minWidth: 85,
        textTransform: 'capitalize',
        color: '#007AFF',
        fontWeight: '500',
        backgroundColor: "transparent",
        border: '1px solid #007AFF',
        boxShadow: 'none',
        lineHeight: '1.2',
        borderRadius: 8,
        '&:hover': {
            backgroundColor: '#007AFF',
            boxShadow: 'none',
            color: '#fff',
        },
        '&.MuiButton-contained.Mui-focusVisible': {
            backgroundColor: "transparent",
            boxShadow: 'none',
            color: '#007AFF',
            transition: 'none'
        },
    },
}))(Button);
