import React from "react";

import Typography from "@material-ui/core/Typography";

import {usePersonalStyles} from "../../../../config/userPersonalStyles";

function CardCount(props) {

    const classes = usePersonalStyles();

    return (
        <div className={classes.card + ' balances'}>
            <div className={classes.box}>
                <Typography variant="h5" component="h5"
                            align="left">Счет</Typography>
                <div className={classes.row}>
                    <div className={classes.balance}>
                        <Typography variant="h6" component="h6"
                                    align="left">Баланс</Typography>
                        <Typography className={classes.desc} variant="body1"
                                    component="div"
                                    align="left">
                            {props.user.balance / 100} ₽
                        </Typography>
                    </div>

                    <div className={classes.balance}>
                        <Typography variant="h6" component="h6"
                                    align="left">Кэшбек</Typography>
                        <Typography className={classes.desc} variant="body1"
                                    component="div"
                                    align="left">

                            {typeof (props.cash) !== 'undefined' ? props.cash.all_sum / 100 + ' ₽' : '??'}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {CardCount};
