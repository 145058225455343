import {userConstants} from "../_constants";

export function userInfo(state = {}, action) {
    switch (action.type) {
        case userConstants.USER_GET_INFO_REQUEST:
            return { loading: true, getUserinfo: false, userInfo: null };
        case userConstants.USER_GET_INFO_SUCCESS:
            return { loading: false, getUserinfo: true, userInfo: action.user };
        case userConstants.USER_GET_INFO_FAILURE:
            return { loading: false, getUserinfo: false, error: action.error };
        default:
            return state
    }
}

