import { combineReducers } from 'redux';

import {authentication} from "./authentication.reducer";
import {registration} from "./registration.reducer";
import {alert} from "./alert.reducer";
import {userInfo} from './userInfo.reducer';
import {check} from "./check.reducer";
import {mentorInfo} from "./mentorInfo.reducer";
import {recruiterInfo} from "./recruiterInfo.reducer";
import {cash} from "./cashAllTime.reducer";

const appReducer = combineReducers({
  authentication,
  registration,
  check,
  mentorInfo,
  recruiterInfo,
  cash,
  userInfo,
  alert
});

const rootReducer = (state, action) => {
  if (action.type === 'USERS_LOGOUT') {
    state = undefined
  }

  return appReducer(state, action);
};

export default rootReducer;
