import React from "react";

const SVG = ({
                 style = {},
                 fill = "#8F90A6",
                 opacity = 0.6,
                 width = "100%",
                 height = "100%",
                 className = "",
                 viewBox = "0 0 155 48"
             }) => (
    <svg
        width={width}
        style={style}
        height={height}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g opacity={opacity}>
            <path d="M149.4 48H5.39998C2.75998 48 0.599976 45.84 0.599976 43.2V4.8C0.599976 2.16 2.75998 0 5.39998 0H149.4C152.04 0 154.2 2.16 154.2 4.8V43.2C154.2 45.84 152.04 48 149.4 48Z" fill={fill} />
            <path d="M33 23.7598C33 20.0398 36.12 18.2398 36.24 18.1198C34.44 15.5998 31.8 15.2398 30.84 15.1198C28.56 14.8798 26.28 16.4398 25.2 16.4398C24 16.4398 22.2 15.1198 20.28 15.1198C17.76 15.1198 15.48 16.5598 14.16 18.8398C11.52 23.3998 13.44 30.1198 15.96 33.8398C17.28 35.6398 18.72 37.6798 20.64 37.5598C22.56 37.4398 23.28 36.3598 25.56 36.3598C27.84 36.3598 28.44 37.5598 30.48 37.5598C32.52 37.5598 33.84 35.7598 35.04 33.9598C36.48 31.9198 37.08 29.8798 37.08 29.7598C36.96 29.6398 33 28.1998 33 23.7598Z" fill="white"/>
            <path d="M29.28 12.7202C30.24 11.4002 30.96 9.72017 30.84 7.92017C29.4 8.04017 27.48 8.88017 26.52 10.2002C25.56 11.2802 24.72 13.0802 24.96 14.7602C26.52 14.8802 28.2 13.9202 29.28 12.7202Z" fill="white"/>
            <path d="M60.36 37.3201H57.72L56.28 32.7601H51.24L49.92 37.3201H47.4L52.44 21.8401H55.56L60.36 37.3201ZM55.8 30.8401L54.48 26.7601C54.36 26.4001 54.12 25.3201 53.64 23.8801C53.52 24.4801 53.28 25.5601 52.92 26.7601L51.6 30.8401H55.8Z" fill="white"/>
            <path d="M73.2 31.68C73.2 33.6 72.72 35.04 71.64 36.24C70.68 37.2 69.6 37.68 68.16 37.68C66.72 37.68 65.64 37.2 65.04 36.12V42H62.52V30C62.52 28.8 62.52 27.6 62.4 26.28H64.56L64.68 28.08C65.52 26.76 66.72 26.04 68.4 26.04C69.72 26.04 70.8 26.52 71.64 27.6C72.72 28.56 73.2 29.88 73.2 31.68ZM70.68 31.68C70.68 30.6 70.44 29.64 69.96 29.04C69.48 28.32 68.76 27.96 67.8 27.96C67.2 27.96 66.6 28.2 66.12 28.56C65.64 28.92 65.28 29.52 65.16 30.12C65.04 30.48 65.04 30.72 65.04 30.84V32.76C65.04 33.6 65.28 34.2 65.76 34.8C66.24 35.4 66.96 35.64 67.68 35.64C68.64 35.64 69.36 35.28 69.84 34.56C70.44 33.84 70.68 32.88 70.68 31.68Z" fill="white"/>
            <path d="M86.0399 31.68C86.0399 33.6 85.5599 35.04 84.4799 36.24C83.5199 37.2 82.4399 37.68 80.9999 37.68C79.5599 37.68 78.4799 37.2 77.8799 36.12V42H75.3599V30C75.3599 28.8 75.3599 27.6 75.2399 26.28H77.3999L77.5199 28.08C78.3599 26.76 79.5599 26.04 81.2399 26.04C82.5599 26.04 83.6399 26.52 84.4799 27.6C85.5599 28.56 86.0399 29.88 86.0399 31.68ZM83.5199 31.68C83.5199 30.6 83.2799 29.64 82.7999 29.04C82.3199 28.32 81.5999 27.96 80.6399 27.96C80.0399 27.96 79.4399 28.2 78.9599 28.56C78.4799 28.92 78.1199 29.52 77.9999 30.12C77.8799 30.48 77.8799 30.72 77.8799 30.84V32.76C77.8799 33.6 78.1199 34.2 78.5999 34.8C79.0799 35.4 79.7999 35.64 80.5199 35.64C81.4799 35.64 82.1999 35.28 82.6799 34.56C83.2799 33.84 83.5199 32.88 83.5199 31.68Z" fill="white"/>
            <path d="M100.44 33.0002C100.44 34.3202 99.9601 35.4002 99.1201 36.2402C98.1601 37.2002 96.7201 37.5602 94.9201 37.5602C93.2401 37.5602 91.9201 37.2002 90.9601 36.6002L91.5601 34.5602C92.6401 35.1602 93.8401 35.5202 95.1601 35.5202C96.1201 35.5202 96.8401 35.2802 97.3201 34.9202C97.8001 34.4402 98.0401 33.9602 98.0401 33.2402C98.0401 32.6402 97.8001 32.0402 97.4401 31.6802C96.9601 31.2002 96.2401 30.8402 95.2801 30.4802C92.5201 29.5202 91.2001 27.9602 91.2001 26.0402C91.2001 24.7202 91.6801 23.7602 92.6401 22.9202C93.6001 22.0802 94.8001 21.7202 96.4801 21.7202C97.9201 21.7202 99.0001 21.9602 99.9601 22.4402L99.3601 24.4802C98.5201 24.0002 97.5601 23.7602 96.3601 23.7602C95.5201 23.7602 94.8001 24.0002 94.3201 24.3602C93.9601 24.7202 93.7201 25.2002 93.7201 25.8002C93.7201 26.4002 93.9601 26.8802 94.4401 27.3602C94.8001 27.7202 95.6401 28.0802 96.7201 28.5602C98.0401 29.0402 99.0001 29.7602 99.7201 30.4802C100.08 31.0802 100.44 31.9202 100.44 33.0002Z" fill="white"/>
            <path d="M108.6 28.0799H105.84V33.4799C105.84 34.7999 106.32 35.5199 107.28 35.5199C107.76 35.5199 108.12 35.5199 108.36 35.3999L108.48 37.3199C108 37.5599 107.4 37.5599 106.56 37.5599C105.6 37.5599 104.76 37.3199 104.28 36.7199C103.68 36.1199 103.44 35.1599 103.44 33.7199V28.0799H101.76V26.1599H103.44V24.1199L105.84 23.3999V26.1599H108.6V28.0799Z" fill="white"/>
            <path d="M120.96 31.6802C120.96 33.3602 120.48 34.8002 119.52 35.8802C118.44 36.9602 117.12 37.5602 115.44 37.5602C113.76 37.5602 112.44 36.9602 111.48 35.8802C110.52 34.8002 110.04 33.4802 110.04 31.8002C110.04 30.1202 110.52 28.6802 111.6 27.6002C112.56 26.5202 114 25.9202 115.68 25.9202C117.36 25.9202 118.68 26.5202 119.64 27.6002C120.48 28.6802 120.96 30.0002 120.96 31.6802ZM118.32 31.8002C118.32 30.7202 118.08 29.8802 117.6 29.1602C117.12 28.3202 116.28 27.8402 115.32 27.8402C114.36 27.8402 113.52 28.3202 113.04 29.1602C112.56 29.8802 112.32 30.8402 112.32 31.8002C112.32 32.8802 112.56 33.7202 113.04 34.4402C113.52 35.2802 114.36 35.7602 115.32 35.7602C116.28 35.7602 117 35.2802 117.6 34.4402C118.08 33.7202 118.32 32.7602 118.32 31.8002Z" fill="white"/>
            <path d="M129 28.32C128.76 28.32 128.52 28.2 128.16 28.2C127.32 28.2 126.6 28.56 126.12 29.16C125.76 29.76 125.52 30.48 125.52 31.32V37.2H123V29.52C123 28.2 123 27.12 122.88 26.04H125.04L125.16 28.2H125.28C125.52 27.48 126 26.88 126.48 26.4C127.08 26.04 127.68 25.8 128.28 25.8C128.52 25.8 128.76 25.8 128.88 25.8V28.32H129Z" fill="white"/>
            <path d="M140.04 31.2002C140.04 31.6802 140.04 32.0402 139.92 32.2802H132.48C132.48 33.3602 132.84 34.2002 133.56 34.8002C134.16 35.2802 135 35.6402 135.96 35.6402C137.04 35.6402 138.12 35.5202 138.96 35.1602L139.32 36.8402C138.24 37.3202 137.04 37.5602 135.6 37.5602C133.92 37.5602 132.48 37.0802 131.52 36.0002C130.56 35.0402 130.08 33.6002 130.08 31.9202C130.08 30.2402 130.56 28.8002 131.4 27.7202C132.36 26.5202 133.68 25.9202 135.36 25.9202C136.92 25.9202 138.24 26.5202 138.96 27.7202C139.8 28.6802 140.04 29.8802 140.04 31.2002ZM137.76 30.6002C137.76 29.8802 137.64 29.2802 137.28 28.6802C136.8 27.9602 136.2 27.6002 135.36 27.6002C134.52 27.6002 133.92 27.9602 133.44 28.5602C133.08 29.0402 132.84 29.7602 132.72 30.4802H137.76V30.6002Z" fill="white"/>
            <path d="M55.0798 12.36C55.0798 13.68 54.7198 14.76 53.8798 15.48C53.1598 16.08 52.0798 16.44 50.6398 16.44C49.9198 16.44 49.3198 16.44 48.8398 16.32V8.88001C49.4398 8.76001 50.1598 8.76001 50.9998 8.76001C52.3198 8.76001 53.2798 9.00001 53.9998 9.60001C54.5998 10.2 55.0798 11.16 55.0798 12.36ZM53.7598 12.36C53.7598 11.52 53.5198 10.8 53.0398 10.32C52.5598 9.84001 51.8398 9.60001 50.9998 9.60001C50.6398 9.60001 50.2798 9.60001 50.0398 9.72001V15.36C50.1598 15.36 50.5198 15.36 50.8798 15.36C51.8398 15.36 52.5598 15.12 53.0398 14.64C53.5198 14.16 53.7598 13.32 53.7598 12.36Z" fill="white"/>
            <path d="M61.8 13.5599C61.8 14.3999 61.56 15.1199 61.08 15.5999C60.6 16.1999 59.88 16.4399 59.04 16.4399C58.2 16.4399 57.6 16.1999 57.12 15.5999C56.64 15.1199 56.4 14.3999 56.4 13.5599C56.4 12.7199 56.64 11.9999 57.12 11.5199C57.6 10.9199 58.32 10.6799 59.16 10.6799C60 10.6799 60.6 10.9199 61.08 11.5199C61.56 11.9999 61.8 12.7199 61.8 13.5599ZM60.6 13.5599C60.6 13.0799 60.48 12.5999 60.24 12.2399C60 11.7599 59.64 11.6399 59.16 11.6399C58.68 11.6399 58.32 11.8799 58.08 12.2399C57.84 12.5999 57.72 13.0799 57.72 13.5599C57.72 14.0399 57.84 14.5199 58.08 14.8799C58.32 15.3599 58.68 15.4799 59.16 15.4799C59.64 15.4799 60 15.2399 60.24 14.7599C60.48 14.5199 60.6 14.0399 60.6 13.5599Z" fill="white"/>
            <path d="M71.04 10.8L69.36 16.32H68.28L67.56 13.9201C67.32 13.3201 67.2 12.72 67.08 12.12C66.96 12.72 66.84 13.3201 66.6 13.9201L65.88 16.32H64.8L63 10.8H64.2L64.8 13.4401C64.92 14.0401 65.04 14.6401 65.16 15.2401C65.28 14.7601 65.4 14.1601 65.64 13.4401L66.36 10.8H67.32L68.04 13.3201C68.28 13.9201 68.4 14.5201 68.52 15.1201C68.64 14.5201 68.76 13.9201 68.88 13.3201L69.6 10.8H71.04Z" fill="white"/>
            <path d="M77.28 16.3201H76.08V13.2001C76.08 12.2401 75.72 11.7601 75 11.7601C74.64 11.7601 74.4 11.8801 74.16 12.1201C73.92 12.3601 73.8 12.7201 73.8 13.0801V16.3201H72.6V12.3601C72.6 11.8801 72.6 11.4001 72.6 10.8001H73.68V11.6401C73.8 11.4001 74.04 11.1601 74.28 10.9201C74.64 10.6801 75 10.5601 75.36 10.5601C75.84 10.5601 76.32 10.6801 76.68 11.0401C77.16 11.4001 77.28 12.0001 77.28 12.8401V16.3201Z" fill="white"/>
            <path d="M80.6399 16.3198H79.4399V8.27979H80.6399V16.3198Z" fill="white"/>
            <path d="M87.8399 13.5599C87.8399 14.3999 87.5999 15.1199 87.1199 15.5999C86.6399 16.1999 85.9199 16.4399 85.0799 16.4399C84.2399 16.4399 83.6399 16.1999 83.1599 15.5999C82.6799 15.1199 82.4399 14.3999 82.4399 13.5599C82.4399 12.7199 82.6799 11.9999 83.1599 11.5199C83.6399 11.0399 84.3599 10.6799 85.1999 10.6799C86.0399 10.6799 86.6399 10.9199 87.1199 11.5199C87.5999 11.9999 87.8399 12.7199 87.8399 13.5599ZM86.6399 13.5599C86.6399 13.0799 86.5199 12.5999 86.2799 12.2399C86.0399 11.7599 85.6799 11.6399 85.1999 11.6399C84.7199 11.6399 84.3599 11.8799 84.1199 12.2399C83.8799 12.5999 83.7599 13.0799 83.7599 13.5599C83.7599 14.0399 83.8799 14.5199 84.1199 14.8799C84.3599 15.3599 84.7199 15.4799 85.1999 15.4799C85.6799 15.4799 86.0399 15.2399 86.2799 14.7599C86.5199 14.5199 86.6399 14.0399 86.6399 13.5599Z" fill="white"/>
            <path d="M93.7199 16.3199H92.6399L92.5199 15.7199C92.1599 16.1999 91.5599 16.4399 90.9599 16.4399C90.4799 16.4399 89.9999 16.3199 89.7599 15.9599C89.5199 15.5999 89.2799 15.2399 89.2799 14.8799C89.2799 14.1599 89.5199 13.6799 90.1199 13.3199C90.7199 12.9599 91.4399 12.8399 92.5199 12.8399V12.7199C92.5199 11.9999 92.1599 11.6399 91.4399 11.6399C90.8399 11.6399 90.4799 11.7599 89.9999 11.9999L89.7599 11.1599C90.2399 10.7999 90.8399 10.6799 91.6799 10.6799C93.1199 10.6799 93.8399 11.3999 93.8399 12.9599V14.9999C93.7199 15.5999 93.7199 15.9599 93.7199 16.3199ZM92.5199 14.3999V13.5599C91.1999 13.5599 90.4799 13.9199 90.4799 14.6399C90.4799 14.8799 90.5999 15.1199 90.7199 15.2399C90.8399 15.3599 91.0799 15.4799 91.3199 15.4799C91.5599 15.4799 91.7999 15.3599 92.0399 15.2399C92.2799 15.1199 92.3999 14.8799 92.5199 14.6399C92.5199 14.6399 92.5199 14.5199 92.5199 14.3999Z" fill="white"/>
            <path d="M100.68 16.3199H99.6V15.4799C99.24 16.1999 98.64 16.4399 97.8 16.4399C97.08 16.4399 96.6 16.1999 96.12 15.7199C95.64 15.2399 95.52 14.5199 95.52 13.6799C95.52 12.8399 95.76 12.1199 96.24 11.5199C96.72 11.0399 97.32 10.7999 97.92 10.7999C98.64 10.7999 99.12 11.0399 99.48 11.5199V8.3999H100.68V14.8799C100.68 15.3599 100.68 15.8399 100.68 16.3199ZM99.48 14.0399V13.0799C99.48 12.9599 99.48 12.8399 99.48 12.7199C99.36 12.4799 99.24 12.2399 99 11.9999C98.76 11.7599 98.52 11.7599 98.16 11.7599C97.68 11.7599 97.32 11.9999 97.08 12.3599C96.84 12.7199 96.72 13.1999 96.72 13.7999C96.72 14.3999 96.84 14.7599 97.08 15.1199C97.32 15.4799 97.68 15.7199 98.16 15.7199C98.52 15.7199 98.88 15.5999 99.12 15.2399C99.36 14.7599 99.48 14.3999 99.48 14.0399Z" fill="white"/>
            <path d="M111.12 13.5599C111.12 14.3999 110.88 15.1199 110.4 15.5999C109.92 16.1999 109.2 16.4399 108.36 16.4399C107.52 16.4399 106.92 16.1999 106.44 15.5999C105.96 15.1199 105.72 14.3999 105.72 13.5599C105.72 12.7199 105.96 11.9999 106.44 11.5199C106.92 10.9199 107.64 10.6799 108.48 10.6799C109.32 10.6799 109.92 10.9199 110.4 11.5199C110.88 11.9999 111.12 12.7199 111.12 13.5599ZM109.8 13.5599C109.8 13.0799 109.68 12.5999 109.44 12.2399C109.2 11.7599 108.84 11.6399 108.36 11.6399C107.88 11.6399 107.52 11.8799 107.28 12.2399C107.04 12.5999 106.92 13.0799 106.92 13.5599C106.92 14.0399 107.04 14.5199 107.28 14.8799C107.52 15.3599 107.88 15.4799 108.36 15.4799C108.84 15.4799 109.2 15.2399 109.44 14.7599C109.68 14.5199 109.8 14.0399 109.8 13.5599Z" fill="white"/>
            <path d="M117.72 16.3201H116.52V13.2001C116.52 12.2401 116.16 11.7601 115.44 11.7601C115.08 11.7601 114.84 11.8801 114.6 12.1201C114.36 12.3601 114.24 12.7201 114.24 13.0801V16.3201H113.04V12.3601C113.04 11.8801 113.04 11.4001 113.04 10.8001H114.12V11.6401C114.24 11.4001 114.48 11.1601 114.72 10.9201C115.08 10.6801 115.44 10.5601 115.8 10.5601C116.28 10.5601 116.76 10.6801 117.12 11.0401C117.6 11.4001 117.72 12.0001 117.72 12.8401V16.3201Z" fill="white"/>
            <path d="M125.88 11.7597H124.56V14.3997C124.56 15.1197 124.8 15.3597 125.28 15.3597C125.52 15.3597 125.64 15.3597 125.88 15.3597V16.3197C125.64 16.4397 125.28 16.4397 124.92 16.4397C124.44 16.4397 124.08 16.3197 123.84 15.9597C123.6 15.7197 123.48 15.1197 123.48 14.5197V11.7597H122.64V10.7997H123.48V9.83974L124.68 9.47974V10.7997H126V11.7597H125.88Z" fill="white"/>
            <path d="M132.36 16.3198H131.16V13.1998C131.16 12.2398 130.8 11.7598 130.08 11.7598C129.48 11.7598 129.12 11.9998 128.88 12.5998C128.88 12.7198 128.88 12.8398 128.88 13.0798V16.3198H127.68V8.27979H128.88V11.6398C129.24 11.0398 129.84 10.6798 130.56 10.6798C131.04 10.6798 131.52 10.7998 131.76 11.1598C132.12 11.5198 132.36 12.2398 132.36 12.9598V16.3198Z" fill="white"/>
            <path d="M138.96 13.32C138.96 13.56 138.96 13.68 138.96 13.92H135.36C135.36 14.52 135.6 14.88 135.84 15.12C136.2 15.36 136.56 15.48 137.04 15.48C137.64 15.48 138.12 15.36 138.48 15.24L138.72 16.08C138.24 16.32 137.64 16.44 136.92 16.44C136.08 16.44 135.36 16.2 134.88 15.72C134.4 15.24 134.16 14.52 134.16 13.68C134.16 12.84 134.4 12.12 134.88 11.64C135.36 11.04 135.96 10.8 136.8 10.8C137.64 10.8 138.24 11.04 138.6 11.64C138.84 12 138.96 12.6 138.96 13.32ZM137.88 12.96C137.88 12.6 137.76 12.24 137.64 12C137.4 11.64 137.16 11.52 136.68 11.52C136.32 11.52 135.96 11.64 135.72 12C135.48 12.24 135.36 12.6 135.36 12.96H137.88Z" fill="white"/>
        </g>
    </svg>
);

export default SVG;
