import React, {useEffect, useState} from "react";

import {usePersonalStyles} from "../../../config/userPersonalStyles";

import {CircularProgress, Container} from "@material-ui/core";
import {userInfoService} from "../../store/_services/userInfo.service";

import {Warning} from "../../components/warning";
import {connect, useDispatch, useSelector} from "react-redux";
import {alertActions, userInfoActions} from "../../store/_actions";
import {isEmptyObj} from "../../store/_helpers";
import {CardPersonal} from "./card-personal";
import {CardCount} from "./card-count";
import {CardCampaigns} from "./card-campaigns";

import {Loader} from "../../components/loader/Loader";
import Typography from "@material-ui/core/Typography";
import SuccessImage from "../../../assets/images/success.svg";

function Personal() {

    const alert = useSelector(state => state.alert);
    const user = useSelector(state => state.userInfo);

    const [recruiterData, setRecruiterData] = useState({status: 'preload', getRecruiter: {}});

    const mentor = useSelector(state => state.mentorInfo);

    const recruiter = useSelector(state => state.recruiterInfo);
    const cash = useSelector(state => state.cash);

    const token = JSON.parse(localStorage.getItem("token"));
    const uuid = JSON.parse(localStorage.getItem("best-discount-uuid"));

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userInfoActions.getUserInfo(token.token));
    }, [])

    useEffect(() => {
        if (isEmptyObj(user.userInfo) === false) {
            if (user.userInfo.status === 'in_approve') {
                dispatch(userInfoActions.getMentor(token.token, uuid));
            }
            if (user.userInfo.status === 'enabled') {
                dispatch(userInfoActions.getRecruiter(token.token));
                dispatch(userInfoActions.getCashAllTime(token.token));
            }
        }
    }, [user.userInfo])

    useEffect(() => {
        if (mentor.getMentor === true) {
            if (mentor.type === 'USER_GET_INFO_MENTOR_FAILURE') {
                setRecruiterData({status: 'error', getRecruiter: false});

            } else if (mentor.type === 'USER_GET_INFO_MENTOR_SUCCESS') {
                setTimeout(() => {
                    dispatch(userInfoActions.getRecruiter(token.token));
                    dispatch(userInfoActions.getCashAllTime(token.token));
                    dispatch(alertActions.clear());
                }, 70000);
            }
        }

        if (recruiter.getRecruiterInfo === true) {
            setRecruiterData({status: 'success', getRecruiter: recruiter.recruiter})
        }

    }, [mentor.type, recruiter]);

    function getRecruiterInfo(status) {
        switch (status.status) {
            case 'success':
                return (
                    <div className={classes.box}>
                        {
                            isEmptyObj(status.getRecruiter) === false ?
                                <div className={classes.rowStart}>
                                    <div className={classes.avatarMaster}
                                         style={{backgroundImage: `url(${status.getRecruiter.photo})`}}>
                                        <div className="tag"> PV {status.getRecruiter.pv_points / 100}</div>
                                    </div>
                                    <div className={classes.info}>
                                        <Typography className={classes.desc} variant="h6" component="h6" align="left">
                                            Ваш наставник {status.getRecruiter.name} {status.getRecruiter.last_name}
                                        </Typography>
                                        <Typography className={classes.listIcon} variant="body1" component="div"
                                                    align="left">
                                            г. {status.getRecruiter.city}
                                        </Typography>
                                        <Typography className={classes.listIcon} variant="body1" component="div"
                                                    align="left">
                                            т. {status.getRecruiter.username}
                                        </Typography>
                                    </div>
                                </div> :

                                <div className={classes.dFlex}>
                                    <img src={SuccessImage} alt="success image" height="164"/>
                                    <div className={classes.info}>
                                        <Typography className={classes.title} variant="h5" component="h5">
                                            Идет процесс подтверждения наставника
                                        </Typography>
                                    </div>
                                </div>
                        }

                    </div>
                );
            case 'error':
                return (
                    <div className={classes.box + " " + classes.dFlex}>
                        <img src={SuccessImage} alt="success image" height="164"/>
                        <div className={classes.info}>
                            <Typography className={classes.title} variant="h5" component="h5">
                                Отсутствует наставник
                            </Typography>
                        </div>
                    </div>
                );
            case 'preload':
                return (
                    <div className={classes.box}>
                        <div className='preload'><CircularProgress/></div>
                    </div>
                );
            default:
                return (
                    <div className={classes.box}>
                        <div className='preload'><CircularProgress/></div>
                    </div>
                );
        }
    }

    const classes = usePersonalStyles();

    return (
        <div className={classes.root}>
            <Container className={classes.root}>

                {alert.message && <Warning {...alert}/>}

                {isEmptyObj(user.userInfo) === false ?
                    <div className={classes.grid}>

                        <CardPersonal user={user.userInfo}/>

                        <CardCount user={user.userInfo} cash={cash.cash}/>

                        <CardCampaigns user={user.userInfo} cash={cash.cash}/>

                        <div className={classes.card + ' master'}>
                            {getRecruiterInfo(recruiterData)}
                        </div>

                    </div> : <Loader/>
                }
            </Container>
        </div>
    );
}

function mapState(state) {
    const {userInfo} = state.userInfo;
    const {recruiterInfo} = state.recruiterInfo;
    const {cashAllInfo} = state.cash;
    const {mentorInfo} = state.mentorInfo;
    return {userInfo, recruiterInfo, cashAllInfo, mentorInfo};
}

const actionCreators = {
    getUserInfo: userInfoService.getUserInfo,
    getMentor: userInfoService.getMentor,
    getRecruiter: userInfoService.getRecruiter,
    getCashAllTime: userInfoService.getCashAllTime,
}

const connectedUserInfoPage = connect(mapState, actionCreators)(Personal);
export {connectedUserInfoPage as Personal};
