import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Container} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import {BlueBorderButton, BlueButton} from "../../../config/Button";
import image from '../../../assets/images/grow.svg';

const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        backgroundColor: '#F2F2F2',
        '& .MuiContainer-root': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiContainer-root': {
                paddingLeft: 0,
                paddingRight: 0
            },
        },
    },
    card: {
        width: '100%',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)',
        borderRadius: 16,

        [theme.breakpoints.down('sm')]: {
            boxShadow: ' none',
            borderRadius: 0,
            minHeight: '100vh'
        }
    },
    row: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 16,
        minHeight: 'calc(100vh - 64px)',

        [theme.breakpoints.down('sm')]: {
           flexDirection: 'column',
            marginTop: 0,
            marginBottom: 0,
        }
    },
    right: {
        display: 'flex',
        alignSelf: 'center',
        alignItems: 'stretch',
        width: '100%',
        marginRight: theme.spacing(7),
        borderRadius: 16,

        [theme.breakpoints.down('xl')]: {
            '&> img': {
                marginLeft: 'auto',
                maxWidth: 590,
                width: '100%',
            },
        },
        [theme.breakpoints.down('lg')]: {
            marginRight: 'auto',

            '&> img': {
                marginRight: 'auto',
                width: '100%',
                height: '100% !important'
            },
        }
    },
    left: {
        maxWidth: 480,
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        marginLeft: theme.spacing(7),

        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3),
            maxWidth: 580,
            marginLeft: 0,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            maxWidth: 580,
            marginLeft: 0,
        },
    },
    title: {
        textAlign: 'left',
        lineHeight: '60px',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        '& .blue': {
            color: '#0063f7'
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(4),
            maxWidth: 580,
            lineHeight: '43px',
        },
        [theme.breakpoints.down('xs')]: {
            lineHeight: '32px',
        }
    },
    rowBtn: {
        maxWidth: 380,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
        },

        [theme.breakpoints.down('xs')]: {
           flexDirection: 'column',
            '& > button': {
                marginBottom: theme.spacing(1),
            },

            '& > div': {
                marginBottom: theme.spacing(1),
            }
        },
    }
}));

function Main(props) {
    const classes = useStyles();

    const openSignUp = (e) => {
        e.preventDefault();
        props.history.push('/sign-up')
    }

    const openSignIn = (e) => {
        e.preventDefault();
        props.history.push('/sign-in')
    }

    return (
        <div className={classes.main}>

            <Container>
                <div className={classes.card}>
                    <div className={classes.row}>
                        <div className={classes.left}>
                            <Typography className={classes.title} variant="h1" component="h1">
                                Активируй партнерскую программу в <span className="blue">Best Discount</span>
                            </Typography>

                            <div className={classes.rowBtn}>
                                <BlueButton onClick={openSignUp} className={classes.button} variant="contained">Зарегистрироваться</BlueButton>
                                <Typography className={classes.link} variant="body1" component="div" align="center">или</Typography>
                                <BlueBorderButton onClick={openSignIn} className={classes.button} variant="contained" color="primary">
                                    Вход
                                </BlueBorderButton>
                            </div>
                        </div>
                        <div className={classes.right}><img src={image} alt="best discount" /></div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export {Main};
