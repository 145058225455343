import { FormControl, withStyles} from "@material-ui/core";

export const BlueFormControl = withStyles((theme) => ({
    root: {
        zIndex: 0,

        '& .MuiFormLabel-root': {
            color: '#8F90A6',
        },

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E4E4EB',
                borderRadius: '8px'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#007AFF',
                borderWidth: '1px',
            },
        },

        '& .MuiInputBase-root': {
            color: '#28293D'
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'rgba(145, 158, 171, 0.32)',
        },

        '& .MuiOutlinedInput-input': {
          padding: '16px 14px 16px 6px'
        },
        '& label.Mui-focused': {
            color: '#007AFF',
        }
    },
}))(FormControl);

