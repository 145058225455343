import React from "react";

import {makeStyles} from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import {Container} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    footer: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        backgroundColor: '#EBEBF0',

        '& .MuiContainer-root': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    },
    box: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 52
    },
    copyright: {
        color: '#555770'
    }
}))

function Footer() {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <Container>
                <div className={classes.box}>
                    <Typography className={classes.copyright} variant="body1" component="div" align="left">
                        Copyright © Best Discount 2021. All Rights Reserved.
                    </Typography>
                </div>
            </Container>
        </footer>
    );
}

export {Footer};
