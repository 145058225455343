import {userConstants} from '../_constants';
import {userInfoService} from "../_services/userInfo.service";
import {alertActions} from "./alert.actions";

export const userInfoActions = {
    getMentor,
    getUserInfo,
    getRecruiter,
    getCashAllTime
};

function getUserInfo(token) {
    return dispatch => {
        dispatch(request());
        userInfoService.getUserInfo(token)
            .then(user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) {
        return {type: userConstants.USER_GET_INFO_REQUEST}
    }

    function success(user) {
        return {type: userConstants.USER_GET_INFO_SUCCESS, user}
    }

    function failure(user) {
        return {type: userConstants.USER_GET_INFO_FAILURE, user}
    }
}

function getMentor(token, uuid) {
    return dispatch => {
        dispatch(request({token, uuid}));

        userInfoService.getMentor(token, uuid)
            .then(mentor => {
                    dispatch(success(mentor));
                    dispatch(alertActions.success([{
                        attribute: "mentor",
                        message: "Идет процесс активации партнерской программы"
                    }]));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(mentor) {
        return {type: userConstants.USER_GET_INFO_MENTOR_REQUEST}
    }

    function success(mentor) {
        return {type: userConstants.USER_GET_INFO_MENTOR_SUCCESS, mentor}
    }

    function failure(mentor) {
        return {type: userConstants.USER_GET_INFO_MENTOR_FAILURE, mentor}
    }
}

function getRecruiter(token) {
    return dispatch => {
        dispatch(request());
        userInfoService.getRecruiter(token)
            .then(recruiterInfo => dispatch(success(recruiterInfo)),
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(recruiterInfo) {
        return {type: userConstants.USER_GET_INFO_RECRUITER_REQUEST, recruiterInfo}
    }

    function success(recruiterInfo) {
        return {type: userConstants.USER_GET_INFO_RECRUITER_SUCCESS, recruiterInfo}
    }

    function failure(recruiterInfo) {
        return {type: userConstants.USER_GET_INFO_RECRUITER_FAILURE, recruiterInfo}
    }
}

function getCashAllTime(token) {
    return dispatch => {
        dispatch(request());
        userInfoService.getCashAllTime(token)
            .then(cashAllInfo => dispatch(success(cashAllInfo)),
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(cashAllInfo) {
        return {type: userConstants.USER_GET_INFO_CASH_ALL_REQUEST, cashAllInfo}
    }

    function success(cashAllInfo) {
        return {type: userConstants.USER_GET_INFO_CASH_ALL_SUCCESS, cashAllInfo}
    }

    function failure(cashAllInfo) {
        return {type: userConstants.USER_GET_INFO_CASH_ALL_FAILURE, cashAllInfo}
    }
}
