import {makeStyles} from "@material-ui/core/styles";
import ErrorImage from '../../../assets/images/404.svg';

import React from "react";
import {Container} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',

        '& .MuiContainer-root': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        },

        [theme.breakpoints.down('xs')]: {
            '& .MuiContainer-root': {
                paddingLeft: 0,
                paddingRight: 0
            },
        },
    },
    box: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)',
        borderRadius: 16,
        minHeight: 'calc(100vh - 148px)',

        [theme.breakpoints.down('xs')]: {
            borderRadius: 0,
        },
    },
    image: {
        margin: '0 auto',
        display: 'flex',
        alignSelf: 'center',
        maxWidth: 700,
        width: 'calc(100% - 32px)'
    }
}));

function Error404() {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container>
                <div className={classes.box}>
                    <img className={classes.image} src={ErrorImage} alt="error image" />
                </div>
            </Container>
        </div>
    );
}

export {Error404};
