
import {handleResponse} from "./user.service";
import {environment} from "../_enviroments/enviroments";

export const userInfoService = {
    getUserInfo,
    getMentor,
    getRecruiter,
    getCashAllTime
};

const url = environment.production;

function getUserInfo(token) {
    return fetch(url + `api/user/owner?token=` + token)
        .then(handleResponse)
}

function getMentor(token, uuid) {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('uuid', uuid);

    const requestOptions = {method: 'POST', mode: 'cors', body: formData};
    return fetch(url + `api/user/approve`, requestOptions).then(handleResponse)
}

function getRecruiter(token) {
    return fetch(url + `api/user/recruiter?token=` + token)
        .then(handleResponse)
}

function getCashAllTime(token) {
    return fetch(url + `api/operation/income?token=` + token).then(handleResponse)
}
