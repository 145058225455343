import React from "react";

import Typography from "@material-ui/core/Typography";

import {usePersonalStyles} from "../../../../config/userPersonalStyles";

function CardCampaigns(props) {

    const classes = usePersonalStyles();

    return (
        <div className={classes.card}>
            <div className={classes.box}>
                <div className={classes.list}>
                    <Typography variant="h6" component="h6"
                                align="left">PV</Typography>
                    <Typography variant="body1" component="div" align="left">
                        {props.user.pv_points / 100}
                    </Typography>
                </div>

                <div className={classes.list}>
                    <Typography variant="h6" component="h6"
                                align="left">Рефералы</Typography>
                    <Typography variant="body1" component="div" align="left">
                        {typeof (props.cash) !== 'undefined' ? props.cash.referral_count : '??'}
                    </Typography>
                </div>

                <div className={classes.list}>
                    <Typography variant="h6" component="h6"
                                align="left">Партнеры</Typography>
                    <Typography variant="body1" component="div" align="left">
                        {typeof (props.cash) !== 'undefined' ? props.cash.partner_count : '??'}
                    </Typography>
                </div>

                <div className={classes.list}>
                    <Typography variant="h6" component="h6"
                                align="left">Компании</Typography>
                    <Typography variant="body1" component="div" align="left">
                        {typeof (props.cash) !== 'undefined' ? props.cash.sponsor_count : '??'}
                    </Typography>
                </div>
            </div>
        </div>
    );
}

export {CardCampaigns};

