
export const customStyles = {

    control: (styles, state) => ({ ...styles,
        borderRadius: 8,
        borderColor: '#e4e4eb',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 400,
        fontSize: '0.875rem',
        minHeight: 48,
        boxShadow: state.isFocused ? '0 0 0 1px #007AFF' : '',

        ':hover': {
            borderColor: state.isFocused ? '#e4e4eb' : '#28293D',
        },
    }),

    valueContainer: (provided, state) => ({
        padding: '2px 14px'
    }),

    menu: (provided, state) => ({
        ...provided,
        margin: '6px 0 0 0',
        color: state.selectProps.menuColor,
        padding: '8px 0',
        boxShadow: '0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)',
        borderRadius: 8,
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 400,
        fontSize: '0.875rem',
    }),

    indicatorSeparator: (provided, state) => ({}),

    // indicatorsContainer: (provided, state) => ({color: '#000'}),

    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        color: state.isSelected ? 'white' : '#28293D',
        padding: 12
    }),

    input: (provided, state) => ({
        padding: 2
    }),

    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    },

    menuList: (base) => ({
        ...base,
        maxHeight: "250px",

        "::-webkit-scrollbar": {
            width: "6px"
        },
        "::-webkit-scrollbar-track": {
            background: "#F5F8FF"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#0063F7"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#004FC4"
        }
    })
}
