import React from "react";
import {useDispatch} from "react-redux";

import {Container} from "@material-ui/core";

import {makeStyles} from "@material-ui/core/styles";

import {BlueBorderButton} from "../../../config/Button";

import {userActions} from "../../store/_actions";

import LogoIcon from "../../../assets/logo/LogoIcon";

const useStyles = makeStyles((theme) => ({
    head: {
        backgroundColor: '#fff',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        boxShadow: '0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16)',

        '& .MuiContainer-root': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    box: {
        marginRight: theme.spacing(2),
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        width: '100%'
    },
    logo: {
        display: "flex",
        alignItems: "center"
    }
}));

function Header() {
    const classes = useStyles();
    const dispatch = useDispatch();

    function logout(e) {
        e.preventDefault()
        dispatch(userActions.logout());
    }

    return (
        <header className={classes.head}>
            <Container>
                <div className={classes.box}>
                    <a href="/" className={classes.logo}>
                        <LogoIcon width={168}/>
                    </a>

                    <BlueBorderButton
                        onClick={logout}
                        className={classes.button} variant="contained" color="primary">
                        Выход
                    </BlueBorderButton>

                </div>
            </Container>
        </header>
    );
}

export {Header};
