import { createMuiTheme } from '@material-ui/core/styles';


import {MontserratBold, MontserratLight, MontserratMedium, MontserratRegular} from "./Fonts";

export const theme = createMuiTheme(
    {
        typography: {
            fontFamily: 'Montserrat, sans-serif'
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    '@font-face': [MontserratRegular, MontserratBold, MontserratLight, MontserratMedium],
                },
            },
        }
    }
);

theme.typography.h1 = {
    fontSize: '48px',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: "bold",

    [theme.breakpoints.only('sm')]: {
        fontSize: '32px',
    },
    [theme.breakpoints.only('xs')]: {
        fontSize: '20px',
    },
};

theme.typography.h2 = {
    fontSize: '32px',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: "bold",
};

theme.typography.h5 = {
    fontSize: '24px',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: "bold",
    // '@media (min-width:600px)': {
    //     fontSize: '1.5rem',
    // },
    // [theme.breakpoints.up('md')]: {
    //     fontSize: '2rem',
    // },
};

theme.typography.h6 = {
    fontSize: '16px',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: "500",
    // '@media (min-width:600px)': {
    //     fontSize: '1.5rem',
    // },
    // [theme.breakpoints.up('md')]: {
    //     fontSize: '2rem',
    // },
};

theme.typography.body1 = {
    fontSize: '14px',
    lineHeight: 'normal',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: "400",
};
