import {makeStyles} from "@material-ui/core/styles";

export const useFormBoxModalStyle = makeStyles((theme) => ({
    root: {
        position: 'relative',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    preload: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

        zIndex: 10,

        '& .MuiCircularProgress-colorPrimary': {
            color: '#555770',
        }
    },
    card: {
        maxWidth: 300,
        width: '100%',
        margin: "0 auto",
        paddingTop: theme.spacing(6),
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(7),
        paddingBottom: theme.spacing(6),
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24)',
        borderRadius: 16,
        [theme.breakpoints.only('xs')]: {
            maxWidth: '100%',
            borderRadius: 0,
            boxShadow: 'none',
            minHeight: '100vh',
            paddingTop: theme.spacing(0),
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
            paddingBottom: theme.spacing(0),
        },
    },
    box: {
        width: '100%',
        margin: "0 auto",
        maxWidth: 300,
        [theme.breakpoints.only('xs')]: {
            paddingTop: theme.spacing(6),
        }
    },
    title: {
        marginTop: theme.spacing(2),
        color: '#28293D'
    },
    subtitle: {
        marginTop: theme.spacing(2),
        color: '#28293D'
    },
    formControl: {
        marginTop: theme.spacing(4)
    },
    icon: {
        color: '#8F90A6'
    },
    cursor: {
        cursor: 'pointer',
    },
    error: {
        color: '#FF5756'
    },
    checkbox: {
        marginLeft: '0 !important',
        marginRight: '0 !important'
    },
    iconCheckbox: {
        marginRight: 14,
        borderRadius: 4,
        width: 18,
        height: 18,
        boxShadow: 'inset 0 0 0 1px #e4e4eb, inset 0 -1px 0 #e4e4eb',
        backgroundColor: '#fff',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto #797979',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#fff',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: '#797979',
        },
    },
    iconCheckboxChecked: {
        backgroundColor: '#007AFF',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        boxShadow: 'none',
        borderRadius: 4,
        marginRight: 14,

        '&:before': {
            display: 'block',
            width: 18,
            height: 18,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#007AFF',
        },
    },
    btnBox: {
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        height: 44,
        width: '100%'
    },
    link: {
        '& span': {
            cursor: 'pointer',
            textDecoration: 'none',
            color: "#0063F7",
            '&:hover': {
                color: "#004FC4",
            }
        },
        '& a': {
            textDecoration: 'none',
            color: "#0063F7",
            '&:hover': {
                color: "#004FC4",
            }
        },

    },
    downloads: {
        marginTop: theme.spacing(4),
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(3),

        '& a:hover g': {
            opacity: 1,
            transition: '.3s'
        }
    }
}));
