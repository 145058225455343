import {makeStyles} from "@material-ui/core/styles";

export const usePersonalStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: '100%',
        flexDirection: 'column',

        '& .MuiContainer-root': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        },
        [theme.breakpoints.down('xs')]: {
            '& .MuiContainer-root': {
                paddingLeft: 0,
                paddingRight: 0
            },
        },
    },
    grid: {
        display: "grid",
        gridTemplateColumns: '280px auto',
        gridGap: theme.spacing(3),
        gridTemplateAreas: `'personal master' 'balances master' 'pv master'`,
        alignItems: 'start',

        '& .personal': {
            gridArea: 'personal'
        },

        '& .master': {
            gridArea: 'master'
        },

        '& .balances': {
            gridArea: 'balances'
        },

        '& .pv': {
            gridArea: 'pv'
        },

        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: ' auto',
            gridGap: theme.spacing(3),
            gridTemplateAreas: `'personal' 'balances' 'pv' 'master'`,
        },
    },
    card: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)',
        borderRadius: 16,
        [theme.breakpoints.down('xs')]: {
            borderRadius: 0,
        }
    },
    box: {
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2),
        },

        "& .preload": {
            display: 'flex',
            justifyContent: 'center',
            zIndex: 10,

            '& .MuiCircularProgress-colorPrimary': {
                color: '#555770',
            }
        },
    },
    dFlex: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-start',

        '& > div': {
            display: 'flex',
            alignSelf: 'center'
        },

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            '& > div': {
                marginTop: theme.spacing(2)
            },
        },
    },
    avatar: {
        margin: '16px auto',
        marginBottom: theme.spacing(4),
        width: 142,
        minWidth: 142,
        height: 142,
        borderRadius: '50%',
        backgroundSize: 'cover',
        border: '1px solid #c3c3c3',
    },
    desc: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    listIcon: {
        display: 'flex',
        marginBottom: theme.spacing(1),
    },
    row: {
        marginTop: theme.spacing(2),
        display: 'grid',
        gridGap: theme.spacing(1),
        gridTemplateColumns: '1fr 1fr'
    },
    balance: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderRadius: 8,
        backgroundColor: 'rgba(0, 79, 196, 0.1)',
    },
    avatarMaster: {
        position: 'relative',
        margin: '0',
        marginBottom: theme.spacing(2),
        width: 142,
        minWidth: 142,
        height: 142,
        borderRadius: '8px',
        backgroundSize: 'cover',
        border: '1px solid #e9eaea',

        '& .tag': {
            position: 'absolute',
            padding: '3px 12px 3px 6px',
            backgroundColor: '#5B8DEF',
            bottom: 10,
            right: 0,
            borderRadius: '6px 0 0 6px',
            fontWeight: 400,
            color: '#fff',
        },
        [theme.breakpoints.down('xs')]: {
            width: 122,
            minWidth: 122,
            height: 122,
        },
    },
    rowStart: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
    },
    info: {
        marginLeft: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 320,

        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        },
    },
    list: {
        display: 'flex',
        width: '100%',
        alignSelf: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),

        "&:last-child": {
            marginBottom: 0,
        }
    }
}));
