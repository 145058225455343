import {userConstants} from "../_constants";

export function recruiterInfo(state = {}, action) {
    switch (action.type) {
        case userConstants.USER_GET_INFO_RECRUITER_REQUEST:
            return {loading: true, getRecruiterInfo: false};
        case userConstants.USER_GET_INFO_RECRUITER_SUCCESS:
            return { recruiter: action.recruiterInfo, loading: false, getRecruiterInfo: true};
        case userConstants.USER_GET_INFO_RECRUITER_FAILURE:
            return {error: action.error, loading: false, getRecruiterInfo: true};
        default:
            return state
    }
}
