import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';

import {connect, useDispatch, useSelector} from 'react-redux';

import {userActions} from "../../../store/_actions";

import Box from '@material-ui/core/Box';
import {Phone, Visibility, VisibilityOff} from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import {CircularProgress, FormHelperText, Icon, InputLabel, OutlinedInput} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import {BlueButton} from '../../../../config/Button';
import {BlueFormControl} from "../../../../config/FormControl";
import {useFormBoxModalStyle} from "../../../../config/formboxModal";

import {TextMaskCustom} from "../../../store/_helpers";
import {escRegExp} from "../../../store/_helpers";

import AppStoreIcon from "../../../../assets/logo/AppStoreIcon";
import GoogleAppIcon from "../../../../assets/logo/GoogleAppIcon";
import {Warning} from "../../../components/warning";

function SignIn() {

    const alert = useSelector(state => state.alert);
    const loader = useSelector(state => state.authentication);

    const [inputs, setInputs] = useState({phone: '', password: ''});
    const {phone, password} = inputs;

    const [isValidNumber, setIsValidNumber] = useState(true);
    const [submitted, setSubmitted] = useState(false);

    const dispatch = useDispatch();
    const location = useLocation();

    const classes = useFormBoxModalStyle();

    const handleChange = (prop) => (event) => {
        setInputs({...inputs, [prop]: event.target.value});
    };
    const handleClickShowPassword = () => {
        setInputs({...inputs, showPassword: !inputs.showPassword});
    };
    const handleChangeLogin = (event) => {
        setInputs({...inputs, [event.target.name]: event.target.value});
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);

        if (phone.length !== 18) {
            setIsValidNumber(false)
        } else if (phone && password) {
            const numb = escRegExp(phone);

            setIsValidNumber(true);

            const {from} = location.state || {from: {pathname: "/home"}};
            dispatch(userActions.login(numb, password, from));
            setSubmitted(false);
        }
    }

    return (
        <div className={classes.root}>

            {loader.loading ? <div className={classes.preload}><CircularProgress /></div> : ''}

            <Box className={classes.card}>

                {alert.message && <Warning {...alert}/>}

                <form className={classes.box} noValidate name="form">
                    <div className={classes.title}>
                        <Typography variant="h5" component="h5" align="center">Вход</Typography>
                    </div>
                    <BlueFormControl fullWidth className={classes.formControl} variant="outlined">
                        <InputLabel htmlFor="phone">Телефон</InputLabel>
                        <OutlinedInput
                            fullWidth
                            id="phone"
                            value={phone}
                            onChange={handleChangeLogin}
                            name="phone"
                            startAdornment={
                                <InputAdornment position="start" className={classes.icon}>
                                    <Icon fontSize="small"><Phone fontSize="small"/></Icon>
                                </InputAdornment>}
                            labelWidth={64}
                            inputComponent={TextMaskCustom}
                        />
                        {
                            isValidNumber ? '' :
                                <FormHelperText id="phone" className={classes.error}>Введите валидный
                                    номер</FormHelperText>
                        }
                    </BlueFormControl>
                    <BlueFormControl fullWidth className={classes.formControl} variant="outlined">
                        <InputLabel htmlFor="password">Пароль</InputLabel>
                        <OutlinedInput
                            fullWidth
                            id="password"
                            name="password"
                            type={inputs.showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={handleChange('password')}
                            startAdornment={
                                <InputAdornment position="start"
                                                className={classes.icon + ' ' + classes.cursor}>
                                    <Icon
                                        fontSize="small"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {inputs.showPassword ? <Visibility fontSize="small"/> :
                                            <VisibilityOff fontSize="small"/>}
                                    </Icon>
                                </InputAdornment>
                            }
                            labelWidth={58}
                        />
                    </BlueFormControl>
                    <div className={classes.btnBox}>
                        <BlueButton
                            disabled={!(phone.length === 18) || !(password.length > 3) || submitted}
                            onClick={handleSubmit}
                            className={classes.button} variant="contained">
                            Войти
                        </BlueButton>
                    </div>
                    <Typography className={classes.link} variant="body1" component="div" align="center">
                        У меня еще нет аккаунта. <Link to={'/sign-up'}>Зарегистрироваться</Link>
                    </Typography>
                    <div className={classes.downloads}>
                        <a target="_blank" rel='noopener noreferrer'
                           href="https://apps.apple.com/ru/app/best-discount/id1212992460">
                            <AppStoreIcon height={44}/>
                        </a>

                        <a target="_blank" rel='noopener noreferrer'
                           href="https://play.google.com/store/apps/details?id=com.bestdiscount.client">
                            <GoogleAppIcon height={44}/>
                        </a>
                    </div>
                    <Typography className={classes.link} variant="body1" component="div" align="center">
                        <Link to={'/'}>Вернуться назад</Link>
                    </Typography>
                </form>
            </Box>
        </div>
    );
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(SignIn);
export { connectedLoginPage as SignIn };
