import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import {Alert} from "@material-ui/lab";

import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiAlert-root': {
            borderRadius: 6,
            boxShadow: '0px 0px 1px rgb(40 41 61 / 8%), 0px 0.5px 2px rgb(96 97 112 / 16%)',

            [theme.breakpoints.down('xs')]: {
                borderRadius: 0,
            },
        },

        '& .MuiAlert-standardError': {
            borderLeft: '4px solid #FF3B3B',
            backgroundColor: '#FFE5E5'
        },

        '& .MuiAlert-standardSuccess': {
            borderLeft: '4px solid #06C270',
            backgroundColor: '#E3FFF1',
        },

        '& .MuiAlert-standardWarning': {
            borderLeft: '4px solid #FF8800',
            backgroundColor: '#FFF8E6'
        },

        '& .MuiSvgIcon-root': {
            fill: '#555770'
        },
        '& .success': {
            backgroundColor: '#FFE5E5'
        },
        '& .MuiAlert-action': {
            marginRight: 0
        }
    },
    column: {
        flexDirection: 'column'
    },
    box: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        width: '100%'
    },
    alert: {
        margin: '0 auto 16px',
        // maxWidth: 488,
    },
    title: {
        color: '#28293D'
    },
    close: {
        cursor: 'pointer'
    }
}));

function Warning(props) {
    const classes = useStyles();

    let data = Array.from(props.message);

    return (
        <div className={classes.root}>
            <div className={classes.column}>
                {data.map((t, i) =>
                    <Alert key={i} className={classes.alert} severity={props.type} icon={false}>
                        <div className={classes.message}>
                            <div className={classes.box}>
                                <Typography className={classes.title} variant="body1" component="div" align="left">
                                    {t.message}
                                </Typography>
                            </div>
                        </div>
                    </Alert>
                )}
            </div>
        </div>
    );
}

export {Warning};
