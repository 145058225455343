import React, {useEffect} from 'react';
import {Router, Route, Switch, Redirect} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {ThemeProvider} from '@material-ui/core/styles';

import {PrivateRoute} from "../../store/_helpers/privateRoute";
import {alertActions} from "../../store/_actions";

import {history} from '../../store/_helpers';

import {theme} from "../../../config/theme";

import {Home} from "../home";
import {SignIn} from "../auth/signIn";
import {SignUp} from "../auth/signup";
import {Main} from "../main";
import {CheckPhone} from "../auth/checkPhone";

import './App.scss';


function App({hideLoader}) {

    const dispatch = useDispatch();

    useEffect(hideLoader, [hideLoader]);

    useEffect(() => {
        history.listen((location, action) => {
            // clear warning on location change
            dispatch(alertActions.clear());
        });
    });

    let params = window.location.search.substr(1);
    if (params) {
        localStorage.setItem('best-discount-uuid', JSON.stringify(window.location.search.substr(3)));
    }

    return (
        <ThemeProvider theme={theme}>
            <div className="wrapper" >

                <Router history={history}>
                    <Switch>
                        <PrivateRoute path="/home" component={Home} {...history} />

                        <Route exact path="/" component={Main} />

                        <Route path="/sign-in" component={SignIn} />

                        <Route path="/sign-up" component={SignUp} />

                        <Route path="/check-phone" component={CheckPhone} history={history}/>

                        <PrivateRoute component={Home} path="*" />

                        <Redirect from="*" to="/"/>
                    </Switch>
                </Router>
            </div>
        </ThemeProvider>
    );
}

export {App};
