import React from "react";

import Typography from "@material-ui/core/Typography";

import {usePersonalStyles} from "../../../../config/userPersonalStyles";


function CardPersonal(props) {

    const classes = usePersonalStyles();

    return (
        <div className={classes.card + ' personal'}>
            <div className={classes.box}>
                <div className={classes.avatar}
                     style={{backgroundImage: `url(${props.user.photo})`}}/>
                <div>
                    <Typography className={classes.title} variant="h6"
                                component="h6"
                                align="center">
                        {props.user.name} {props.user.last_name}
                    </Typography>
                    <Typography className={classes.desc} variant="body1"
                                component="div"
                                align="center">
                        г. {props.user.city}
                    </Typography>
                    <Typography className={classes.desc} variant="body1"
                                component="div"
                                align="center">
                        {props.user.username}
                    </Typography>
                </div>
            </div>
        </div>
    );
}

export {CardPersonal};
