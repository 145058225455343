import {Route, Switch} from "react-router-dom";

import {makeStyles} from "@material-ui/core/styles";

import {Footer} from "../../components/footer";
import {Header} from "../../components/header/Header";

import {Error404} from "../error404";
import {Personal} from "../personal";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "grid",
        gridGap: theme.spacing(2),
        gridTemplateRows: "50px minmax(calc(100vh - 148px), auto) 66px"
    },
    box: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center"
    }
}));

function Home(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Header />

            <Switch>
                <Route path="/home">
                    <Personal {...props} />
                </Route>

                <Route component={Error404}/>
            </Switch>

            <Footer />
        </div>
    );
}

export {Home};
