export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    CHECK_CODE_REQUEST: 'CHECK_CODE_REQUEST',
    CHECK_CODE_SUCCESS: 'CHECK_CODE_SUCCESS',
    CHECK_CODE_FAILURE: 'CHECK_CODE_FAILURE',

    USER_GET_INFO_REQUEST: 'USER_GET_INFO_REQUEST',
    USER_GET_INFO_SUCCESS: 'USER_GET_INFO_SUCCESS',
    USER_GET_INFO_FAILURE: 'USER_GET_INFO_FAILURE',

    USER_GET_INFO_MENTOR_REQUEST: 'USER_GET_INFO_MENTOR_REQUEST',
    USER_GET_INFO_MENTOR_SUCCESS: 'USER_GET_INFO_MENTOR_SUCCESS',
    USER_GET_INFO_MENTOR_FAILURE: 'USER_GET_INFO_MENTOR_FAILURE',

    USER_GET_INFO_RECRUITER_REQUEST: 'USER_GET_INFO_RECRUITER_REQUEST',
    USER_GET_INFO_RECRUITER_SUCCESS: 'USER_GET_INFO_RECRUITER_SUCCESS',
    USER_GET_INFO_RECRUITER_FAILURE: 'USER_GET_INFO_RECRUITER_FAILURE',

    USER_GET_INFO_CASH_ALL_REQUEST: 'USER_GET_INFO_CASH_ALL_REQUEST',
    USER_GET_INFO_CASH_ALL_SUCCESS: 'USER_GET_INFO_CASH_ALL_SUCCESS',
    USER_GET_INFO_CASH_ALL_FAILURE: 'USER_GET_INFO_CASH_ALL_FAILURE',

    COUNTRY_GET_INFO_REQUEST: 'COUNTRY_GET_INFO_REQUEST',
    COUNTRY_GET_INFO_SUCCESS: 'COUNTRY_GET_INFO_SUCCESS',
    COUNTRY_GET_INFO_FAILURE: 'COUNTRY_GET_INFO_FAILURE',

};
