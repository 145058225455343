
import {userService} from "./user.service";
import {environment} from "../_enviroments/enviroments";

export const citiesService = {
    getListCities,
    getListCountries
};

const url = environment.production;

 function getListCities(countryId) {
    return fetch(url + `/api/info/cities` + '?country_id=' + countryId)
        .then(handleResponse)
        .then(cities => {
            return cities.cities
        })
}

async function getListCountries() {
    return await fetch(url + `/api/info/countries`)
        .then(handleResponse)
        .then(countries => {
            return countries.countries
        })
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {

            const error = (data.type && data.error) || data.error;
            return Promise.reject(error);

            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                window.location = '/';
            }
        }
        return data;
    });
}
