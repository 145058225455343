import {userConstants} from '../_constants';

export function check(state = {}, action) {
    switch (action.type) {
        case userConstants.CHECK_CODE_REQUEST:
            return { registering: true, loading: true, type: userConstants.CHECK_CODE_REQUEST };
        case userConstants.CHECK_CODE_SUCCESS:
            return {loading: false, type: userConstants.CHECK_CODE_SUCCESS};
        case userConstants.CHECK_CODE_FAILURE:
            return {loading: false, type: userConstants.CHECK_CODE_FAILURE};
        default:
            return state
    }
}
