import {Checkbox, withStyles} from "@material-ui/core";
import React from "react";

export const BlueCheckbox = withStyles({
    root: {
        color: "#797979",
        padding: 0
    },
    checked: {},
})(
    (props) => <Checkbox color="default" {...props} />);
