import React from "react";

const SVG = ({
                 style = {},
                 fill = "#fff",
                 width = "100%",
                 height = "100%",
                 className = "",
                 viewBox = "0 0 49 34"
             }) => (
    <svg
        width={width}
        style={style}
        height={height}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill={fill}>
            <path
                fillRule="evenodd" clipRule="evenodd"
                d="M5.61343 12.453V3.02851C5.61343 1.76724 5.20019 0 2.8278 0C0.455403 0 0 1.76724 0 3.02851V29.6109C0 31.0955 0.506189 32.656 2.51676 32.656C4.52734 32.656 5.20019 30.6795 5.20019 30.6795C5.20019 30.6795 10.0448 34.314 15.9981 32.656C21.9513 30.998 26.0848 25.3818 26.0848 25.3818C27.0122 30.9914 35.9108 36.8006 43.2131 30.2789C43.2131 30.2789 43.8266 32.656 46.091 32.656C48.3553 32.656 49 30.9961 49 29.8304V3.02851C49 2.04907 48.4825 0 46.091 0C43.6994 0 43.2131 1.93757 43.2131 3.02851V12.0881C37.5919 8.70556 31.5447 10.1824 28.9795 12.453C26.4143 14.7236 23.1088 20.131 23.1088 20.131C23.1088 20.131 22.2114 13.442 16.8698 11.0304C11.5283 8.6188 5.61343 12.453 5.61343 12.453ZM11.5644 28.5266C14.9457 28.5266 17.6867 25.5871 17.6867 21.9609C17.6867 18.3348 14.9457 15.3953 11.5644 15.3953C8.18317 15.3953 5.44212 18.3348 5.44212 21.9609C5.44212 25.5871 8.18317 28.5266 11.5644 28.5266ZM43.5363 21.9609C43.5363 25.5871 40.7953 28.5266 37.414 28.5266C34.0328 28.5266 31.2917 25.5871 31.2917 21.9609C31.2917 18.3348 34.0328 15.3953 37.414 15.3953C40.7953 15.3953 43.5363 18.3348 43.5363 21.9609Z" />
        </g>
    </svg>
);

export default SVG;
