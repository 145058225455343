import {userConstants} from '../_constants';
import {userService} from '../_services';
import {history} from '../_helpers';
import {alertActions} from "./alert.actions";

export const userActions = {
    login,
    register,
    checkCode,
    logout
};

function login(username, password, from) {
    return dispatch => {
        dispatch(request({username}));
        userService.login(username, password)
            .then(
                token => {
                    dispatch(success(token.token));
                    history.push(from);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) {
        return {type: userConstants.LOGIN_REQUEST, username: user.username}
    }

    function success(user) {
        return {type: userConstants.LOGIN_SUCCESS, token: user, username}
    }

    function failure(error) {
        return {type: userConstants.LOGIN_FAILURE, error}
    }
}

function logout() {
    userService.logout().then(
        user => {
            history.push('/')
        },
    );
    return {type: userConstants.LOGOUT};
}

function register(phone, user) {
    return dispatch => {
        dispatch(request({user}));
        userService.register(phone, user.selectedCountry.id, user.selectedCity.id)
            .then(
                user => {
                    dispatch(success(user));
                    history.push({
                        pathname: '/check-phone',
                        phone: phone
                    });
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) {
        return {type: userConstants.REGISTER_REQUEST, user}
    }

    function success(user) {
        return {type: userConstants.REGISTER_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.REGISTER_FAILURE, error}
    }
}

function checkCode(code, phone, from) {
    return dispatch => {
        dispatch(request({code}));
        userService.checkCode(code, phone)
            .then(
                token => {
                    dispatch(success(token.token));
                    history.push(from);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) {
        return {type: userConstants.CHECK_CODE_REQUEST, user}
    }
    function success(user) {
        return {type: userConstants.CHECK_CODE_SUCCESS, user}
    }
    function failure(error) {
        return {type: userConstants.CHECK_CODE_FAILURE, error}
    }
}
