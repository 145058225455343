import {userConstants} from '../_constants';

export function cash(state = {}, action) {
    switch (action.type) {
        case userConstants.USER_GET_INFO_CASH_ALL_REQUEST:
            return { cash: action.cashAllInfo, loading: true };
        case userConstants.USER_GET_INFO_CASH_ALL_SUCCESS:
            return {cash: action.cashAllInfo, loading: false};
        case userConstants.USER_GET_INFO_CASH_ALL_FAILURE:
            return {loading: false};
        default:
            return state
    }
}
