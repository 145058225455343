import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));

const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
        return {
            loggingIn: true,
            username: action.username,
            loading: true
        };
        case userConstants.LOGIN_SUCCESS:
        return {
            loggedIn: true,
            username: action.username,
            token: action.token,
            loading: false
        };
        case userConstants.LOGIN_FAILURE:
        return {
            loading: false,
            error: action.error.map(s => s.message)
        };
        case userConstants.LOGOUT:
        return {
            loading: false,
        };
        default:
        return state
  }
}
