import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from 'react-redux';

import InputAdornment from "@material-ui/core/InputAdornment";

import {NumberFormatCustom} from "../../../store/_helpers";
import {userActions} from "../../../store/_actions";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {CircularProgress, Icon, InputLabel, OutlinedInput} from "@material-ui/core";

import {useFormBoxModalStyle} from "../../../../config/formboxModal";
import {BlueButton} from "../../../../config/Button";
import {BlueFormControl} from "../../../../config/FormControl";

import {Textsms} from "@material-ui/icons";
import {Link, useLocation} from "react-router-dom";
import {Warning} from "../../../components/warning";

function CheckPhone(props) {

    const alert = useSelector(state => state.alert);
    const loader = useSelector(state => state.authentication);

    const number = props.history.location.phone;
    const classes = useFormBoxModalStyle();

    const [codes, setCodes] = useState({code: ''});
    const [submitted, setSubmitted] = useState(false);

    const dispatch = useDispatch();
    const location = useLocation();

    const handleChange = (event) => {
        setCodes({...codes, [event.target.name]: event.target.value});
    };

    function handleSubmit(e) {
        e.preventDefault()
        setSubmitted(true);
        if (codes.code) {
            const {from} = location.state || {from: {pathname: "/home"}};
            dispatch(userActions.checkCode(codes.code, number, from));
            setSubmitted(false);
        }
    }

    return (
        <div className={classes.root}>

            {loader.loading ? <div className={classes.preload}><CircularProgress/></div> : ''}

            <Box className={classes.card}>

                {alert.message && <Warning {...alert}/>}

                <form className={classes.box}>

                    <div className={classes.title}>
                        <Typography variant="h5" component="h5" align="center">Подтверждение пароля</Typography>
                    </div>
                    <div className={classes.subtitle}>
                        <Typography variant="body1" component="div" align="center">Введите последние 5 цифр номера, с которого поступит звонок</Typography>
                    </div>

                    <BlueFormControl fullWidth className={classes.formControl} variant="outlined">
                        <InputLabel htmlFor="code">Введите код подтверждения</InputLabel>
                        <OutlinedInput
                            name="code"
                            fullWidth
                            id="code"
                            onChange={handleChange}
                            value={codes.code}
                            startAdornment={
                                <InputAdornment position="start" className={classes.icon} fontSize="small">
                                    <Icon fontSize="small"><Textsms fontSize="small"/></Icon>
                                </InputAdornment
                                >}
                            labelWidth={214}
                            inputComponent={NumberFormatCustom}
                        />
                    </BlueFormControl>

                    <div className={classes.btnBox}>
                        <BlueButton className={classes.button}
                                    variant="contained"
                                    color="primary"
                                    disabled={codes.code.length !== 5 || submitted}
                                    onClick={handleSubmit}
                        >
                            Отправить код
                        </BlueButton>
                    </div>

                    <Typography className={classes.link} variant="body1" component="div" align="center">
                        <Link to={'/sign-up'}>Вернуться назад</Link>
                    </Typography>
                </form>
            </Box>

        </div>
    );
}

function mapState(state) {
    const { checkPhone } = state.check;
    return { checkPhone };
}

const actionCreators = {
    checkCode: userActions.checkCode,
};

const connectedLoginPage = connect(mapState, actionCreators)(CheckPhone);
export { connectedLoginPage as CheckPhone };
