import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';

import AsyncSelect from 'react-select/async';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
    CircularProgress, FormControl,
    FormControlLabel,
    Icon,
    InputLabel, MenuItem,
    OutlinedInput, Select
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import {BlueButton} from "../../../../config/Button";
import {BlueCheckbox} from "../../../../config/Checkbox";
import {BlueFormControl} from "../../../../config/FormControl";
import {useFormBoxModalStyle} from "../../../../config/formboxModal";

import {customStyles} from "../../../../config/Select";

import {escRegExp, isEmptyObj, TextMaskCustom} from "../../../store/_helpers";

import AppStoreIcon from "../../../../assets/logo/AppStoreIcon";
import GoogleAppIcon from "../../../../assets/logo/GoogleAppIcon";

import {citiesService} from "../../../store/_services";
import {userActions} from "../../../store/_actions";
import {Phone} from "@material-ui/icons";
import {Link} from "react-router-dom";
import {Warning} from "../../../components/warning";

function SignUp() {

    const alert = useSelector(state => state.alert);
    const loader = useSelector(state => state.registration);

    const [state, setState] = useState({
        textMask: '',
        selectedCountry: [],
        selectedCity: [],
        agree: false,
    });

    const [listCities, getListCities] = useState([]);

    const [submitted, setSubmitted] = useState(false);

    const handleChangeLogin = (event) => {
        setState({...state, [event.target.name]: event.target.value});
    };

    const dispatch = useDispatch();

    const getCountries = (input) => {
        if (input) {
            return citiesService.getListCountries().then(countries => {
                let array = [];
                Array.from(countries).map((country) => {
                    if (country.name.toLowerCase().includes(input.toLowerCase())) {
                        array.push({id: country.id, label: country.name})
                    }
                })
                return array;
            })
        } else {
            return citiesService.getListCountries().then(countries => {
                let array = [];
                Array.from(countries).map((country) => array.push({id: country.id, label: country.name}))
                return array;
            })
        }
    }

    const changeCountryOption = (e) => {
        setState({...state, selectedCountry: e, selectedCity: []});

        citiesService.getListCities(e.id).then(cities => {
            let array = [];
            Array.from(cities).map((city) => array.push({id: city.id, label: city.name}))
            getListCities(array);
        })
    }
    const getCountryValue = (option) => {
        return option.value || option.id;
    };
    const getCountryLabel = (option) => {
        return option.label || option.name;
    };

    const getCities = async input => {
        if (input) {
            return citiesService.getListCities(state.selectedCountry.id).then(cities => {
                let array = [];
                Array.from(cities).map((city) => {
                    if (city.name.toLowerCase().includes(input.toLowerCase())) {
                        array.push({id: city.id, label: city.name})
                    }
                })
                return array;
            })
        } else {
            return citiesService.getListCities(state.selectedCountry.id).then(cities => {
                let array = [];
                Array.from(cities).map((city) => array.push({id: city.id, label: city.name}))
                return array;
            })
        }
    }

    const changeCityOption = (e) => {
        setState({...state, selectedCity: e});
    }
    const getCityValue = (option) => {
        return option.value || option.id;
    };
    const getCityLabel = (option) => {
        return option.label || option.name;
    };

    const handleChange = (event) => {
        setState({...state, agree: event.target.checked});
    };

    function handleSubmit(e) {
        e.preventDefault()
        setSubmitted(true);
        if (state.textMask && state.selectedCountry && state.selectedCity) {
            const numb = escRegExp(state.textMask);
            dispatch(userActions.register(numb, state));
            setSubmitted(false);
        }
    }

    const {agree} = state;
    const classes = useFormBoxModalStyle();

    return (
        <div className={classes.root}>

            {loader.loading ? <div className={classes.preload}><CircularProgress/></div> : ''}

            <Box className={classes.card}>
                {alert.message && <Warning {...alert}/>}

                <form className={classes.box}>
                    <div className={classes.title}>
                        <Typography variant="h5" component="h5" align="center">Регистрация</Typography></div>
                    <BlueFormControl fullWidth className={classes.formControl} variant="outlined">
                        <InputLabel htmlFor="phone">Телефон</InputLabel>
                        <OutlinedInput
                            value={state.textMask}
                            onChange={handleChangeLogin}
                            name="textMask"
                            fullWidth
                            id="phone"
                            startAdornment={
                                <InputAdornment position="start" className={classes.icon} fontSize="small">
                                    <Icon fontSize="small"><Phone fontSize="small"/></Icon>
                                </InputAdornment>}
                            labelWidth={64}
                            inputComponent={TextMaskCustom}
                        />
                    </BlueFormControl>

                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        className={classes.formControl}
                        value={state.selectedCountry}
                        styles={customStyles}
                        loadOptions={getCountries}
                        onChange={changeCountryOption}
                        getOptionValue={getCountryValue}
                        getOptionLabel={getCountryLabel}
                        isSearchable={true}
                        placeholder="Выберите страну..."
                        label="Single select"
                    />

                    {isEmptyObj(state.selectedCountry) === false ?
                        <AsyncSelect
                            cacheOptions
                            defaultOptions={listCities}
                            className={classes.formControl}
                            styles={customStyles}
                            value={state.selectedCity}
                            loadOptions={getCities}
                            onChange={changeCityOption}
                            getOptionValue={getCityValue}
                            getOptionLabel={getCityLabel}
                            isSearchable={true}
                            placeholder="Выберите город..."
                            label="Single select"
                        /> : ''
                    }

                    <FormControlLabel
                        className={classes.checkbox + ' ' + classes.formControl}
                        color="primary"
                        control={
                            <BlueCheckbox
                                id="agree"
                                checked={agree}
                                name="agree"
                                onChange={handleChange}
                                inputProps={{'aria-label': 'decorative checkbox'}}
                                icon={<span className={classes.iconCheckbox}/>}
                                checkedIcon={<span className={classes.icon + ' ' + classes.iconCheckboxChecked}/>}
                            />}
                        label={(
                            <label htmlFor="agree">
                                <Typography className={classes.link} variant="body1" component="div" align="center">
                                    Я соглашаюсь с <a href="http://dev.app-bd.com/documents/clients.pdf"
                                                      target="_blank" rel="noopener noreferrer">
                                    правилами сайта</a>
                                </Typography>
                            </label>
                        )}
                    />

                    <div className={classes.btnBox}>
                        <BlueButton
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            disabled={
                                !agree || !(state.textMask.length === 18) ||
                                !(isEmptyObj(state.selectedCountry) === false)
                                || !(isEmptyObj(state.selectedCity) === false)
                                || submitted}
                            onClick={handleSubmit}
                        >
                            Подтвердить номер
                        </BlueButton>
                    </div>

                    <Typography className={classes.link} variant="body1" component="div" align="center">
                        У меня уже есть аккаунт. <Link to={'/sign-in'}>Войти</Link>
                    </Typography>
                    <div className={classes.downloads}>
                        <a
                            target="_blank" rel='noopener noreferrer'
                            href="https://apps.apple.com/ru/app/best-discount/id1212992460"
                        >
                            <AppStoreIcon height={44}/>
                        </a>
                        <a
                            target="_blank" rel='noopener noreferrer'
                            href="https://play.google.com/store/apps/details?id=com.bestdiscount.client"
                        >
                            <GoogleAppIcon height={44}/>
                        </a>
                    </div>
                    <Typography className={classes.link} variant="body1" component="div" align="center">
                        <Link to={'/'}>Вернуться назад</Link>
                    </Typography>
                </form>
            </Box>
        </div>
    );
}

function mapState(state) {
    const {registering} = state.registration;
    return {registering};
}

const actionCreators = {
    register: userActions.register
}

const connectedRegisterPage = connect(mapState, actionCreators)(SignUp);
export {connectedRegisterPage as SignUp};
